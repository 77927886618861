export const extractImagePathUrl = (image, withWidth = false) => {
  try {
    if (!image) {
      return "";
    }

    if (image.url.startsWith("/")) {
      // dev mode
      return `${
        process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337"
      }${image.url}${withWidth ? ` ${image.width}w` : ""}`;
    } else {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        // if cdn is not ready
        return `${image.url}${withWidth ? ` ${image.width}w` : ""}`;
      } else {
        return `${process.env.GATSBY_CDN_IMAGE_URL}${image.hash}${image.ext}${
          withWidth ? ` ${image.width}w` : ""
        }`;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

import { getLocaleValue } from "./get-locale-value";

export const getLocaleValueFromArrayByName = (locale, array, name) => {
  const selectedElement = array.find(a => {
    return a.name === name;
  });

  const result = getLocaleValue(locale, selectedElement.variants);

  return result;
};
